import * as React from "react"
import Layout from "../components/layout"
import pdf from "../images/newEPK.pdf"

const EPK = () => (
  <Layout>
    <embed
      src={`${pdf}#toolbar=0&navpanes=0&scrollbar=0`}
      width="100%"
      height="1000px"
    />
  </Layout>
)

export default EPK
